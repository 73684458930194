import { Injectable, signal } from '@angular/core';
import { CourseI } from './interfaces/course.interface';
import { FilterCoursesI } from './interfaces/filter-courses.interface';
import { CloI } from '../../shared/interfaces/clo.interface';
import { FilterCourseSectionsI } from '../course-sections/interfaces/filter-course-sections.interface';
import { CourseSectionI } from '../course-sections/interfaces/course-section.interface';
import { AcademicYearI } from '../academic-years/academic-year/interfaces/academic-year.interface';
import { SemesterType } from '../academic-years/academic-year/enums/semester-type.enum';
import { Plo } from './interfaces/aol-course.interface';

@Injectable({
  providedIn: 'root',
})
export class CoursesService {
  constructor() {}

  filterCourses = signal<FilterCoursesI | null>(null);

  selectedCourse = signal<CourseI | null>(null);

  editCourse = signal<CourseI | null>(null);

  addCourse = signal<CourseI | null>(null);

  deleteCourse = signal<CourseI | null>(null);

  selectedAcademicYear = signal<AcademicYearI | null>(null);

  selectedSemester = signal<SemesterType | null>(null);

  selectedPlo = signal<Plo | null>(null);

  // ******************************************** ||

  editClo = signal<CloI | null>(null);

  addClo = signal<CloI | null>(null);

  deleteClo = signal<CloI | null>(null);

  // ******************************************** ||

  filterCourseSections = signal<FilterCourseSectionsI | null>(null);

  editCourseSection = signal<CourseSectionI | null>(null);

  addCourseSection = signal<CourseSectionI | null>(null);

  deleteCourseSection = signal<CourseSectionI | null>(null);
}
